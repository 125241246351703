import axios from "axios";
import React, { useState } from "react";
export const API_URL = "http://13.127.124.179:8080";

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] =   localStorage.getItem("access_token");


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function apiPOST(url, data, config = {}) {
    // const accessToken = localStorage.getItem("access_token");
    // axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
    return axiosApi
      .post(url, data, { ...config })
      .then(response => response).catch((error) => error.response)
  }

  
  