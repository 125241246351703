
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Chats from "./pages/UsersChat/Chats";
import Home from "./pages/Home/Home";
// import Welcome from "./pages/Welcome/Welcome";

export default function App(){
  return(
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<Home />} />
      <Route path="/chats" element={<Chats />}>
      {/* <Route path="/welcome" element={<Welcome />}> */}
        {/* <Route path="blogs" element={<Blogs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NoPage />} /> */}
      </Route>
    </Routes>
  </BrowserRouter>
  )
}