import './Chat.css';
import addIcon from "../../assets/add-30.png"
import msgIcon from '../../assets/message.svg'
import sendLogo from '../../assets/send.svg'
import userIcon from '../../assets/user-icon.png'
import gptImgLogo from '../../assets/chatgptLogo.svg'
import sdlcLogo from '../../assets/Layer 3.svg'
import imgLogo from '../../assets/Group 4.svg'
import reGenerate from '../../assets/Vector.svg'
import voiceLogo from '../../assets/Rectangle 693.svg'
import trippleLogo from '../../assets/Group.svg'
import { Link } from 'react-router-dom';
import {apiPOST } from '../../../src/utils/apiHelper';
import React, {useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Charts() {
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const sendQuery = async () => {
    let payload = {
      "query": query
    }
    try {
      setLoading(true)
      const response = await apiPOST("/rag", payload);
      console.log("response..........", response?.data);
      if (response?.status == 200) {
        let arr = [];
        arr = [...data]
        arr.push(response?.data)
        setData(arr)
        setQuery("")
      }
      setLoading(false)
    } catch (error) {
      console.log("error")
      setLoading(false)
      toast("Something went wrong")
    }

  }

  const uploadfile = async (file) => {
    try {
      console.log("file..", file);
      const url = 'http://13.127.124.179:8080/embedding/pdf';
      const formData = new FormData();
      formData.append('upload_file', file);
      //  formData.append('fileName', file.name);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      setLoading3(true)
      console.log("-----------", url, formData, config)
      axios.post(url, formData, config).then((response) => {
        //console.log(response.data);
        if (response.status == 200) {
          toast("File uploaded")
          setLoading3(false)
        }
      });
      setLoading3(false)
    } catch (error) {
      //console.log("error..", error);
      toast("Something went wrong")
      setLoading3(false)
    }
  }

  const regenerate = async (query) => {
    let payload = {
      "query": query
    }
    try {
      setLoading2(true)
      const response = await apiPOST("/rag", payload);
      console.log("response..........", response?.data);
      if (response?.status == 200) {
        let arr = [];
        arr = [...data]
        arr.push(response?.data)
        setData(arr)

      }
      setLoading2(false)
    } catch (error) {
      console.log("error")
      setLoading(false)
    }

  }



  return (
    <div className="main2">
      <div className='card'>
        <div className="sideBar">
          <div className="upperSide">
            <div className="upperSideTop">
              <Link to='/'><img src={sdlcLogo} alt="Logo" className="logo" /></Link>
              <span className="brand"></span>
            </div>
            <div>
              <p>Recent</p>
            </div>
            <div className="upperSideBottom">
              <button className="quary"><img src={msgIcon} alt="" />What is programming? <img src={trippleLogo} className="quaryImg" alt='' /></button>
              {/* <button className="quary"><img src={msgIcon} alt="" className="" />How to use an API?  <img src={trippleLogo} className="quaryImg" alt='' /></button> */}
            </div>
          </div>


          <div className="lowerSide">
            <div className='listItems'>
              <button className="midBtn"><img src={addIcon} alt="addchat" className="addBtn" />New Chat</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <div className='card2'>
        <div className="mainDiv">

          {data.map((item) => (
            <div className='chatsDiv'>
              <div className='chat'>
                <img src={userIcon} alt='' className='chatImg' />
                <p className='txt'>{item?.query}</p>
              </div>

              <div className='chat bot'>
                <img src={gptImgLogo} alt='' className='chatImg' />
                <p className='txt'>{item?.result}</p>

              </div>



              <div className='reGentxtDiv' onClick={() => {
                regenerate(item?.query);
              }}>
                {!loading2 ?
                  <img src={reGenerate} alt='refresh' className='refreshLogo' />
                  : <Spinner animation="border" variant="primary" />
                }
                <span>Regenerate</span></div>

              {/* <div className='reGentxt'><span><b>Regenerate</b></span></div> */}
            </div>
          ))}


          <div className='chatFooter'>
            <div className='inp'>
              <button className='sendVoice'><img src={voiceLogo} alt='send' /></button>
              <input
                placeholder="Enter Prompt here"
                type="text"
                name="query"
                id="query"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
              {!loading3 ?
                <div>
                  <input
                    type='file'
                    accept='application/pdf'
                    id="fileInput"
                    onChange={(e) => { uploadfile(e.target.files[0]) }}
                    style={{ display: "none" }}

                  />
                  <label htmlFor="fileInput" className="custom-file-input">
                    <img src={imgLogo} alt="Upload" />
                  </label>

                </div> :
                <Spinner animation="border" variant="primary" />}

              {!loading ?
                <button

                  disabled={query ? false : true}
                  className='send'

                  onClick={() => {
                    sendQuery();


                  }}
                ><img src={sendLogo} alt='send' /></button>
                :
                <Spinner animation="border" variant="primary" />}


            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Charts;
