import React from 'react'
import './Home.css'
import sdlcLogo from '../../assets/Layer 3.svg'
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="main3">
      <div className='btnDiv'><b>AI Chatboat </b></div>
      <div className="center-box">
        <img src={sdlcLogo} alt="Your Image" className="image" />
        <div className="content">
          <p>Welcome to aur AI new chatbot with fully functional and </p>
          <p>context - aware capabilites!</p>
          <button className='btn'><Link className='btnLink' to='/chats'>Get Started</Link></button>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default Home